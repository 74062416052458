import { types } from "./type";
import API from '../api';
import {
    message
  } from 'antd';
const {dashboardCount,washStatus,alertStatus,revenuesGenerated,monthlyEstimate,monthlyInvoice,monthlyTicket,pickupList,approvalList}=API

export const getDashboardCount= () => (dispatch) => {
    dashboardCount().then((res)=>{
        if(res.data.success===true){
            dispatch({
                type: types.DASHBOARD_COUNT,
                data: res.data.data
              })
            
        }else{
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
    }).catch((err)=>{
        // message.error({ content: `${err?.response?.data.message}`, duration: 2 });
    })
};

export const getWashStatus= () => (dispatch) => {
    washStatus().then((res)=>{
        if(res.data.success===true){
            const data=res.data.data
			const _data=[data?.PreWash, data?.InWash, data?.Complete]
            dispatch({
                type: types.WASH_STATUS,
                data: _data
              })
            
        }else{
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
    }).catch((err)=>{
        message.error({ content: `${err?.response?.data.message}`, duration: 2 });
    })
};

export const getAlertStatus= () => (dispatch) => {
    alertStatus().then((res)=>{
        if(res.data.success===true){
            const data=res.data.data
			const _data=[data?.lessThan_5,data?.five,data?.six,data?.seven,data?.greaterThan_7]
            dispatch({
                type: types.ALERT_STATUS,
                data: _data
              })
            
        }else{
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
    }).catch((err)=>{
        // message.error({ content: `${err?.response?.data.message}`, duration: 2 });
    })
};

export const getRevenuesGenerated= (count) => (dispatch) => {

    revenuesGenerated(count).then((res)=>{
        if(res.data.success===true){
            let _data=[]
            if(res.data.data&&res.data.data.length!==0){
                _data=res.data.data
                }else{
                    _data=[{
                        "total_amount": "No",
                        "customer_details": {
                            "name": "",
                            "first_name": "testing",
                            "last_name": "pushkar"
                        },
                    },
                ]
                }
            dispatch({
                type: types.REVENUES_GENERATED,
                data: _data
              })
            
        }else{
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
    }).catch((err)=>{
        // message.error({ content: `${err?.response?.data.message}`, duration: 2 });
    })
};

export const getMonthlyEstimate= (count) => (dispatch) => {
    monthlyEstimate().then((res)=>{
    // monthlyEstimate(count).then((res)=>{
        if(res.data.success===true){
            const data=res.data.data
            dispatch({
                type: types.MONTHLY_ESTIMATE,
                data: data
              })
            
        }else{
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
    }).catch((err)=>{
        // message.error({ content: `${err?.response?.data.message}`, duration: 2 });
    })
};

export const getMonthlyInvoice= (count) => (dispatch) => {
    monthlyInvoice().then((res)=>{
    // monthlyInvoice(count).then((res)=>{
        if(res.data.success===true){
            const data=res.data.data
            console.log("dataAction",data)
            dispatch({
                type: types.MONTHLY_INVOICE,
                data: data
              })
            
        }else{
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
    }).catch((err)=>{
        // message.error({ content: `${err?.response?.data.message}`, duration: 2 });
    })
};

export const getMonthlyTicket= (count) => (dispatch) => {
    monthlyTicket().then((res)=>{
    // monthlyTicket(count).then((res)=>{
        if(res.data.success===true){
            const data=res.data.data
            dispatch({
                type: types.MONTHLY_TICKET,
                data: data
              })
            
        }else{
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
    }).catch((err)=>{
        // message.error({ content: `${err?.response?.data.message}`, duration: 2 });
    })
};

export const getEquipmentPickup= () => (dispatch) => {
    pickupList().then((res)=>{
        if(res.data.success===true){
            const data=res.data.data
            dispatch({
                type: types.EQUIPMENT_AWAITING_PICKUP,
                data: data
              })
            
        }else{
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
    }).catch((err)=>{
        // message.error({ content: `${err?.response?.data.message}`, duration: 2 });
    })
};

export const getTicketAwaiting= () => (dispatch) => {
    approvalList().then((res)=>{
        if(res.data.success===true){
            const data=res.data.data
            dispatch({
                type: types.TICKET_AWAITING_APPROVAL,
                data: data
              })
            
        }else{
            message.error({ content: `${res.data.message}`, duration: 2 });
        }
    }).catch((err)=>{
        // message.error({ content: `${err?.response?.data.message}`, duration: 2 });
    })
};