import React,{useState,useEffect} from 'react';
import Chart from '../../../components/Charts/Chart';
import _get from 'lodash/get';
import {ChartMonthlyColor} from '../../../utils/helper' 
import { useDispatch } from 'react-redux';
import { getMonthlyTicket } from '../../../actions/dashboardAction';
const MonthlyTicket=({type, location, labels,data })=>{

  const dispatch = useDispatch();

  useEffect(()=>{
    dispatch(getMonthlyTicket())
  },[])
        let arr=[]
        const  label=[]
        let  chartData = {
            labels: label,
            datasets:[ 
              {
                label: 'Ticket',
                data: arr,
                // backgroundColor: ChartMonthlyColor,
                backgroundColor: [
                  '#FFB07B',
                  '#FFE3D1',
                  '#FFEFE5',
                  '#FFC59E',
                  '#FD8F46',
                  '#FFB07B',
                  '#FFE3D1',
                  '#FFEFE5',
                  '#FFC59E',
                  '#FD8F46',
                  '#FFB07B',
                  '#FFE3D1',
                ],
                borderWidth: 2,
              }],
            
          };

          data&&data.map((item)=>{
            label.push(item.month)
                arr.push(item.total)
          })    

          const days=[
            {value:7,displayValue:'Last 7 Days'},
            {value:15,displayValue:'Last 15 Days'},
            {value:30,displayValue:'Last 30 Days'},
            {value:90,displayValue:'Last 90 Days'},
          ]

          const dropDownSelect=(e)=>{
            dispatch(getMonthlyTicket(e.target.value))
          }
          
          const form =
           <select
           virtual={false}
                      allowClear={false}
                      placeholder={`SELECT DAYS`}
                      defaultValue={7}
                      // onChange={(e) => dropDownSelect(e)}
                    >
                      {days.map((item) => (
                          <option value={item.value}>
                            {item.displayValue}
                          </option>
                        ))}
                    </select>
        
      
        return (<>{
          data&&data.length!==0&&
            <Chart
            form={form}
              type={type}
              chartData={chartData}
              location={location}
              legendPosition="bottom"
            />
        }</>
        );
        // return<div>djk</div>
      
}
export default MonthlyTicket;
